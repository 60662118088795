:root {
  font-size: 112.5%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
}

.disable-webkit-callout {
  -webkit-touch-callout: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

div#appleid-signin {
  min-width: 300px;
  min-height: 40px;
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  font-style: normal;
  src: url('/static/fonts/Inter/Inter-ExtraLight-200.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  src: url('/static/fonts/Inter/Inter-Light-300.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Inter/Inter-Regular-400.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url('/static/fonts/Inter/Inter-Medium-500.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: url('/static/fonts/Inter/Inter-SemiBold-600.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Inter/Inter-Bold-700.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: normal;
  src: url('/static/fonts/Inter/Inter-ExtraBold-800.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  src: url('/static/fonts/Inter/Inter-Black-900.ttf');
}

@font-face {
  font-family: 'Lora';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Lora/Lora-Regular.ttf');
}

@font-face {
  font-family: 'Lora';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Lora/Lora-Bold.ttf');
}

@font-face {
  font-family: 'Lora';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Lora/Lora-Italic.ttf');
}

@font-face {
  font-family: 'Merriweather';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Merriweather/Merriweather-Regular.ttf');
}

@font-face {
  font-family: 'Merriweather';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Merriweather/Merriweather-Bold.ttf');
}

@font-face {
  font-family: 'Merriweather';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Merriweather/Merriweather-Italic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Open_Sans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Open_Sans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Open_Sans/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Roboto/Roboto-Italic.ttf');
}

@font-face {
  font-family: 'Crimson Text';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Crimson_Text/CrimsonText-Regular.ttf');
}

@font-face {
  font-family: 'Crimson Text';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Crimson_Text/CrimsonText-Bold.ttf');
}

@font-face {
  font-family: 'Crimson Text';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Crimson_Text/CrimsonText-Italic.ttf');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/OpenDyslexic/OpenDyslexicAlta-Regular.otf');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/OpenDyslexic/OpenDyslexicAlta-Bold.otf');
}

@font-face {
  font-family: 'OpenDyslexic';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/OpenDyslexic/OpenDyslexicAlta-Italic.otf');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Source_Serif_Pro/SourceSerifPro-Bold.ttf');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Source_Serif_Pro/SourceSerifPro-Italic.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Montserrat/Montserrat-Italic.ttf');
}

@font-face {
  font-family: 'Newsreader';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Newsreader/Newsreader-Regular.ttf');
}

@font-face {
  font-family: 'Newsreader';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Newsreader/Newsreader-Bold.ttf');
}

@font-face {
  font-family: 'Newsreader';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Newsreader/Newsreader-Italic.ttf');
}

@font-face {
  font-family: 'AtkinsonHyperlegible';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/AtkinsonHyperlegible/AtkinsonHyperlegible-Regular.ttf');
}

@font-face {
  font-family: 'AtkinsonHyperlegible';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/AtkinsonHyperlegible/AtkinsonHyperlegible-Bold.ttf');
}

@font-face {
  font-family: 'AtkinsonHyperlegible';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/AtkinsonHyperlegible/AtkinsonHyperlegible-Italic.ttf');
}

@font-face {
  font-family: 'LXGWWenKai';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/LXGWWenKai/LXGWWenKai-Regular.ttf');
}

@font-face {
  font-family: 'LXGWWenKai';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/LXGWWenKai/LXGWWenKai-Bold.ttf');
}

@font-face {
  font-family: 'SF Mono';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/SFMono/SFMonoRegular.otf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/SourceSansPro/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/SourceSansPro/SourceSansPro-Bold.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/SourceSansPro/SourceSansPro-Italic.ttf');
}


@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/IBMPlexSans/IBMPlexSans-Bold.ttf');
}

@font-face {
  font-family: 'IBMPlexSans';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/IBMPlexSans/IBMPlexSans-Italic.ttf');
}

@font-face {
  font-family: 'Lexend';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Lexend/Lexend-Regular.ttf');
}

@font-face {
  font-family: 'Lexend';
  font-weight: 700;
  font-style: normal;
  src: url('/static/fonts/Lexend/Lexend-Bold.ttf');
}

@font-face {
  font-family: 'Fraunces';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Fraunces/Fraunces-Variable.ttf');
}

@font-face {
  font-family: 'Fraunces';
  font-weight: 700;
  font-style: bold;
  src: url('/static/fonts/Fraunces/Fraunces-Variable.ttf');
}


@font-face {
  font-family: 'Fraunces';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Fraunces/Fraunces-Italic.ttf');
}


@font-face {
  font-family: 'Literata';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/Literata/Literata-Variable.ttf');
}

@font-face {
  font-family: 'Literata';
  font-weight: 700;
  font-style: bold;
  src: url('/static/fonts/Literata/Literata-Variable.ttf');
}


@font-face {
  font-family: 'Literata';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/Literata/Literata-Italic.ttf');
}

@font-face {
  font-family: 'SuperNotesPro';
  font-weight: 400;
  font-style: normal;
  src: url('/static/fonts/SNPro/SNPro-VariableRegular.ttf');
}

@font-face {
  font-family: 'SuperNotesPro';
  font-weight: 700;
  font-style: bold;
  src: url('/static/fonts/SNPro/SNPro-Bold.ttf');
}


@font-face {
  font-family: 'SuperNotesPro';
  font-weight: 400;
  font-style: italic;
  src: url('/static/fonts/SNPro/SNPro-Italic.ttf');
}

@font-face {
  font-family: 'FuturaBold';
  src: url('/static/fonts/FuturaBold/FuturaBold.otf');  
  font-weight: bold;  
}


.dropdown-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-style: solid;
  border-width: 4px 4px 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 1px;
  background: var(--colors-grayBorderHover);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px !important;
  height: 16px;
  border-radius: 50%;
  background: var(--colors-utilityTextContrast);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  width: 16px !important;
  height: 16px;
  border-radius: 50%;
  background: var(--colors-utilityTextContrast);
  cursor: pointer;
}

select {
  color: var(--colors-grayTextContrast);
  background-color: transparent;
  -webkit-appearance: none;
}

select option {
  background-color: transparent;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--colors-grayTextContrast);
}

button {
  padding: 0px;
  margin: 0px;
}

.slide-panel-overlay {
  z-index: 100 !important;
  background: transparent !important;
  pointer-events: none;
}
  
.slide-pane__content {
  padding: 0px !important;
  pointer-events: all;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 1px solid var(--colors-thNotebookBorder);
  background: var(--colors-thNotebookBackground);
}

.slide-pane {
  background: transparent !important;
  transition: transform .2s !important;
  box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.1) !important;
}

@media (max-width: 600px) {
  .slide-pane__content {
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: 1px solid var(--colors-thNotebookBorder);
  }
}
