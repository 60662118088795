.article-inner-css * {
  max-width: 100%;
}

:root {
  color-scheme: var(--colorScheme-colorScheme);
}

.speakingSection {
  color: var(--colors-highlightText);
  background-color: rgba(255, 210, 52, 0.2);
}

.highlight {
  color: var(--colors-highlightText);
  background-color: rgba(var(--colors-highlightBackground), 0.35);
  border-bottom: 2px rgb(var(--colors-highlightBackground)) solid;
  border-radius: 2px;
  cursor: pointer;
}


.highlight__green {
  background-color: rgba(var(--colors-highlight_background_green), var(--colors-highlight_background_alpha));
  border-bottom: 2px solid rgba(var(--colors-highlight_background_green), var(--colors-highlight_underline_alpha));
}

.highlight__red {
  background-color: rgba(var(--colors-highlight_background_red), var(--colors-highlight_background_alpha));
  border-bottom: 2px solid rgba(var(--colors-highlight_background_red), var(--colors-highlight_underline_alpha));
}

.highlight__blue {
  background-color: rgba(var(--colors-highlight_background_blue), var(--colors-highlight_background_alpha));
  border-bottom: 2px solid rgba(var(--colors-highlight_background_blue), var(--colors-highlight_underline_alpha));
}

.highlight__yellow {
  background-color: rgba(var(--colors-highlight_background_yellow), var(--colors-highlight_background_alpha));
  border-bottom: 2px solid rgba(var(--colors-highlight_background_yellow), var(--colors-highlight_underline_alpha));
}

.article-inner-css {
  font-family: var(--text-font-family);
}

.article-inner-css .highlight_note_button {
  display: unset !important;
  margin: 0px !important;
  max-width: unset !important;
  height: unset !important;
  padding: 0px 8px;
  cursor: pointer;
  margin-left: 5px !important;
}

.article-inner-css .highlight_label_button {
  display: unset !important;
  margin: 0px !important;
  max-width: unset !important;
  height: unset !important;
  padding: 0px 8px;
  cursor: pointer;
  margin-left: 4px !important;
}

.article-inner-css h1,
.article-inner-css h2,
.article-inner-css h3,
.article-inner-css h4,
.article-inner-css h5,
.article-inner-css h6 {
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: var(--line-height);
  font-size: var(--text-font-size);
  font-weight: bold;
}
.article-inner-css h1 {
  font-size: 1.5em !important;
  line-height: 1.4em !important;
}
.article-inner-css h2 {
  font-size: 1.43em !important;
}
.article-inner-css h3 {
  font-size: 1.0em !important;
}
.article-inner-css h4,
.article-inner-css h5,
.article-inner-css h6 {
  font-size: 1em !important;
  margin: 1em 0 !important;
}

.article-inner-css .scrollable {
  overflow: auto;
}

.article-inner-css div {
  line-height: var(--line-height);
  /* font-size: var(--text-font-size); */
  color: var(--font-color);
}

.article-inner-css p {
  font-style: normal;
  font-weight: normal;

  color: var(--font-color);
  text-align: var(--text-align);

  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  > img {
    display: block;
    margin: 0.5em auto !important;
    max-width: 100% !important;
  }

  > iframe {
    width: 100%;
    height: 350px;
  }
}

.article-inner-css section {
  line-height: 1.65em;
  font-size: var(--text-font-size);
}

.article-inner-css blockquote {
  display: block;
  border-left: 1px solid var(--font-color-transparent);
  padding-left: 16px;
  font-style: italic;
  margin-inline-start: 0px;
  > * {
    font-style: italic;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}

.article-inner-css a {
  color: var(--font-color-readerFont);
}

.article-inner-css .highlight a {
  color: var(--colors-highlightText);
}

.article-inner-css figure {
  * {
    color: var(--font-color-transparent);
  }

  margin: 0;
  font-size: 0.75em;
  line-height: 1.5em;

  figcaption {
    color: var(--font-color);
    opacity: 0.7;
    margin: 10px 20px 10px 0;
  }

  figcaption * {
    color: var(--font-color);
    opacity: 0.7;
  }
  figcaption a {
    color: var(--font-color);
    /* margin: 10px 20px 10px 0; */
    opacity: 0.6;
  }

  > div {
    max-width: 100%;
  }
}

.article-inner-css figure[aria-label='media'] {
  margin: var(--figure-margin);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-inner-css hr {
  margin-bottom: var(--hr-margin);
  border: none;
}

.article-inner-css table {
  display: block;
  word-break: normal;
  white-space: nowrap;

  border-spacing: 0;
  border-collapse: collapse;
  font-size: 0.8rem;
  margin: auto;
  line-height: 1.5em;
  font-size: 0.9em;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  caption {
    margin: 0.5em 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  img {
    display: block;
    margin: 0.5em auto !important;
    max-width: 100% !important;
  }
}

.article-inner-css table th {
  padding: 5px;

  text-align: center;
  vertical-align: middle;
  border-color: var(--colors-thTextSubtle3);
  border-style: solid;
  border-width: 1px;
}
.article-inner-css table td {
  text-align: center;
  vertical-align: middle;
  border-color: var(--colors-thTextSubtle3);
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}

.article-inner-css ul,
.article-inner-css ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  margin-top: 18px;

  font-family: var(--text-font-family);
  font-style: normal;
  font-weight: normal;

  line-height: var(--line-height);
  font-size: var(--text-font-size);

  color: var(--font-color);
}

.article-inner-css li {
  word-break: break-word;
  ol,
  ul {
    margin: 0;
  }
  text-align: left;
}

.article-inner-css sup,
.article-inner-css sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  a {
    color: inherit;
    pointer-events: none;
  }
}

.article-inner-css sup {
  top: -0.75em;
}

.article-inner-css sub {
  bottom: 0.3em;
}

.article-inner-css cite {
  font-style: normal;
}

.article-inner-css .page {
  width: 100%;
}

/* Collapse excess whitespace. */
.page p > p:empty,
.page div > p:empty,
.page p > div:empty,
.page div > div:empty,
.page p + br,
.page p > br:only-child,
.page div > br:only-child,
.page img + br {
  display: none;
}

.article-inner-css video {
  max-width: 100%;
}

.article-inner-css dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.article-inner-css dd {
  display: block;
  margin-inline-start: 40px;
}

.article-inner-css pre,
.article-inner-css code {
  vertical-align: bottom;
  word-wrap: initial;
  font-family: 'SF Mono', monospace !important;
  white-space: break-spaces;
  direction: ltr;
  unicode-bidi: embed;
  color: var(--font-color);
  margin: 0;
  overflow-x: auto;
  word-wrap: normal;
  border-radius: 4px;
}

.article-inner-css img {
  display: block;
  margin: 0.5em auto !important;
  max-width: 100% !important;
  height: auto;
}

.article-inner-css .page {
  word-wrap: break-word;

  font-size: var(--text-font-size);
  line-height: var(--line-height);
}

.article-inner-css .omnivore-instagram-embed {
  img {
    margin: 0 !important;
  }
}

.article-inner-css .morning-brew-markets {
  max-width: 100% !important;
}

.article-inner-css .morning-brew-markets tbody{
  width: 100%;
  display: table;
}

.article-inner-css .morning-brew-markets td:nth-child(1) {
  width: 20%;
}

.article-inner-css .morning-brew-markets td:nth-child(2) {
  width: 30%;
}

.article-inner-css .morning-brew-markets td:nth-child(3) {
  width: 30%;
}

.article-inner-css .morning-brew-markets td:nth-child(4) {
  width: 20%;
}

.article-inner-css ._omnivore-static-tweet {
  color: #0F1419 !important;
  background: #ffffff !important;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 550px;
  margin: 32px auto;
  border: 1px solid #e0e0e0;
  direction: ltr;
  border-radius: 8px;
  padding: 16px 16px ;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
}

.article-inner-css ._omnivore-static-tweet ._omnivore-static-quote-tweet {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
}

.article-inner-css ._omnivore-static-tweet ._omnivore-static-quote-tweet-header {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
  justify-content: flex-start;
}

.article-inner-css ._omnivore-static-tweet ._omnivore-static-quote-tweet-header-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: unset !important;
}

.article-inner-css ._omnivore-static-tweet ._omnivore-static-quote-tweet-header-text {
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 14px;
  line-height: 20px;
}

._omnivore-static-tweet ._omnivore-static-tweet-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  margin: unset;
  justify-content: flex-start;
  color: #0F1419 !important;
}


._omnivore-static-tweet-header ._omnivore-static-tweet-header-text {
  display: flex;
  flex-direction: column;
  color: #0F1419 !important;
  font-size: 14px;
  line-height: 20px;
}

._omnivore-static-tweet-header-text .tweet-author-name {
  font-weight: 600;
}

._omnivore-static-tweet-header-text .tweet-author-handle {
  color: #808080;
}


._omnivore-static-tweet ._omnivore-static-tweet-fake-link {
  color: #1da1f2;
}

._omnivore-static-tweet ._omnivore-static-tweet-text {
  font-size: 14px;
  line-height: 20px;
  color: #0F1419 !important;
}

._omnivore-static-tweet ._omnivore-static-tweet-link-top {
  text-decoration: none;
}

._omnivore-static-tweet ._omnivore-static-tweet-header-avatar {
  -ms-interpolation-mode: bicubic; 
  border: none !important;
  border-radius: 50%;
  float: left;
  height: 48px;
  width: 48px;
  margin: unset !important;
  margin-right: 12px;
  max-width: 100%;
  vertical-align: middle;
}

._omnivore-static-tweet ._omnivore-static-tweet-link-bottom {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  white-space: pre-wrap;
}

._omnivore-static-tweet ._omnivore-static-tweet-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #808080;
  font-size: 14px;
  line-height: 20px;
}

._omnivore-static-tweet ._omnivore-static-tweet-footer hr {
  background: #e0e0e0;
  border: none;
  height: 1px;
  margin: 12px 0;
  padding: 0;
  width: 100%;
  color: #496F72;
  font-size: 14px;
  line-height: 20px;
}

._omnivore-static-tweet ._omnivore-static-tweet-author-handle {
  display: block;
}

._omnivore-static-tweet ._omnivore-static-tweet-ufi {
  display: flex;
  gap: 24px;
  align-items: center;
}

._omnivore-static-tweet ._omnivore-static-tweet-ufi .likes, .retweets {
  display: flex;
  gap: 4px;
  text-decoration: none;
  color: #808080;
  font-size: 14px;
  line-height: 20px;
}

._omnivore-static-tweet ._omnivore-static-tweet-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

._omnivore-static-tweet .expanded-link {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
}

._omnivore-static-tweet .expanded-link-img {
  cursor: pointer;
  width: 100%;
  max-height: 280px;
  max-width: 100%;
  object-fit: cover;
  margin: 0px auto !important
}

._omnivore-static-tweet .expanded-link img {
  margin: 0px auto !important
}

._omnivore-static-tweet .expanded-link-bottom {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 2px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.expanded-link-bottom .expanded-link-domain {
  color: #808080;
  text-transform: lowercase;
  text-decoration: none;
}

.expanded-link-bottom .expanded-link-title {
  color: #0F1419 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.expanded-link-bottom .expanded-link-description {
  color: #808080;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.article-inner-css ._omnivore_tweet_content {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.is-sticky {
  position: fixed;
  right: 5px;
  bottom: 5px;
  top: auto;
  left: auto;
  z-index: 10;
  max-width: 400px;
  max-height: 222px;
  width: 400px;
  height: 222px;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.1) !important;
}

@media (max-width: 600px) {
  .is-sticky {
    max-width: 200px;
    max-height: 110px;
  }
}


@keyframes fadeInUp {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
  }
  100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
  }
  100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
  }
}

.youtubeplaceholder {
  border: 2px dashed #aaa;
  box-sizing: border-box;
}

